<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg10 xl7>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-note-multiple-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSincronizar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <!-- :error-messages="nomeErrors" 
                              required
                  @input="$v.varAtualizacaoLocal.nome_atualizacao.$touch()"
                  @blur="$v.varAtualizacaoLocal.nome_atualizacao.$touch()"-->

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varAtualizacaoLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    type="date"
                    hide-details="auto"
                    v-model="varAtualizacaoLocal.data"
                    label="Data"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="7" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varAtualizacaoLocal.tabela"
                    label="Tabela"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varAtualizacaoLocal.local"
                    label="Local"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <v-textarea
                    dense
                    hide-details="auto"
                    v-model="varAtualizacaoLocal.observacoes"
                    label="Observações"
                    rows="3"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("tabAtualizacoes");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   varAtualizacaoLocal: {
  //     nome_atualizacao: { required },
  //     email: { required, email },
  //     atualizacao: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "TabAtualizacoesForm",
    tituloForm: "",
    varAtualizacaoLocal: {},
  }),

  computed: {
    ...mapState(["staAtualizacaoSelect", "listaUsuarios"]),
  },

  watch: {
    // atualizacaoSelect(atualizacaoNovo) {
    //   this.metSincronizar(atualizacaoNovo);
    // },
  },

  created() {
    this.metSincronizar(this.staAtualizacaoSelect);
    if (this.staAtualizacaoSelect) {
      this.tituloForm = "Editar Atualização";
    } else {
      this.tituloForm = "Nova Atualização";
    }
    this.metDatasDB();
  },

  methods: {
    ...mapActions([
      "actSelecionarAtualizacao",
      "actResetarAtualizacao",
      "actCriarAtualizacao",
      "actEditarAtualizacao",
      "actResetarErro",
    ]),

    selecionarAtualizacaoEdicao(atualizacao) {
      this.actSelecionarAtualizacao({ atualizacao });
    },

    async salvarAtualizacao() {
      switch (this.staAtualizacaoSelect) {
        case undefined:
          this.varAtualizacaoLocal.criacao =
            this.$store.state.login.usuario.usuario;
          this.varAtualizacaoLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          // this.varAtualizacaoLocal.criado = undefined;
          // this.varAtualizacaoLocal.modificado = undefined;
          await this.actCriarAtualizacao({
            atualizacao: this.varAtualizacaoLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.metDatasDB();
          if (this.$store.state.tabAtualizacoes.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.tabAtualizacoes.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Atualização";
            this.$root.snackbar.show({
              type: "success",
              message: "Atualização Criada!",
            });
          }
          break;
        default:
          this.varAtualizacaoLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varAtualizacaoLocal.criacao = undefined;
          this.varAtualizacaoLocal.criado = undefined;
          this.varAtualizacaoLocal.modificado = undefined;
          await this.actEditarAtualizacao({
            atualizacao: this.varAtualizacaoLocal,
            licenca: this.$store.state.login.licenca,
          });
          // this.metDatasDB();
          if (this.$store.state.tabAtualizacoes.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.tabAtualizacoes.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Atualizacao Salva!",
            });
          }
      }
      //this.resetar();
    },

    metDatasForm() {
      this.varAtualizacaoLocal.data = this.mixDataFormD(
        this.varAtualizacaoLocal.data
      );
    },

    metDatasDB() {
      this.varAtualizacaoLocal.data = this.mixDataDBDCal(
        this.varAtualizacaoLocal.data
      );
    },

    metSincronizar(atualizacao) {
      this.varAtualizacaoLocal = Object.assign(
        {},
        atualizacao || {
          id: null,
        }
      );

      /* this.varAtualizacaoLocal.criado = this.mixDataDBT(
        this.varAtualizacaoLocal.criado
      ); */
    },
    submit() {
      // this.$v.$touch();
      this.salvarAtualizacao();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varAtualizacaoLocal.atualizacao = this.staAtualizacaoSelect.atualizacao;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
